@import "src/styles/base";

.container{
    margin-top: 30px;
}

.password{
    margin-top: 15px;
}

.signin{
    margin-top: 40px;
    margin-bottom: 60px;
}