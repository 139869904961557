@import "src/styles/base";

.forgot_password_container{
    margin-top: 30px;
}

.lower_panel{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
}

.lower_text{
    opacity: 0.8;
    @include font(400, 18px, 140%);
}

hr{
    margin-top: 85px;

    @include mobile{
        margin-top: 25px;
    }
}
