@import "src/styles/base";
$search_background_color : #282489;

.hidden {
    display: none;
}

.search_field {
    @include mobile {
        @include font(400, 16px, 24px);
        height: 30px;
        border-radius: 4px;
        padding-left: 10px;
    }
    @include tablet {
        @include font(400, 14px, 21px);
        height: 30px;
    }

    @include font(400, 16px, 24px);
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background-image: url(../../assets/search-icon.svg);
    background-position: right 5% center;
    background-repeat: no-repeat;
    padding-left: 15px;
    padding-right: 35px;
    background-color: $search_background_color;
    color: #FFFFFF;
    border: none;

    &::placeholder {
        @include font(400, 16px, 24px);
        @include mobile{
            @include font(400, 16px, 24px);
        }
        @include tablet {
            @include font(400, 14px, 21px);
        }
        color: #FFFFFF;
        opacity: 0.7;
    }
}

.search_field:focus {
    outline: none;
    background-image: url(../../assets/search-icon-light.svg);
}
