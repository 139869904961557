@import "src/styles/base";

.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tbody {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.content_container {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  flex: 1;

  @include scroller(0, 0, hidden, auto);
}

.content_tr {
  @include font(400, 16px, 24px);
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(95, 95, 211, 0.2);
}

.checkbox_container {
  display: flex;
  width: 24px;
  height: 24px;
}

.image_container {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 14px;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.row_cell {
  width: auto;
}

.row_cell_checkbox {
  flex: 0 2 auto !important;
  width: 27px;
}

.row_cell_image {
  width: 64px;
  flex: 0 1 auto !important;
}
