@import "src/styles/base";

.marquee {
    @include desktop {
        animation: none;

        &:hover {
            animation: marquee 9s linear infinite;
        }
    }

    animation: marquee 9s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(0);
    }

    65% {
        transform: translateX(-100%);
    }

    75% {
        transform: translateX(-100%);
    }

    85% {
        transform: translateX(0);
    }
}