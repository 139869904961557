@import "src/styles/base";

.button_title {
  @include font(400, 16px, 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  text-align: start;
  transition: 0.09s;

  &:hover {
    font-weight: bold;
  }
}

.marquee_title {
  width: 240px;
  overflow: hidden;
  white-space: nowrap;
  @include font(400, 16px, 24px);
}

.subtext_container {
  @include font(400, 16px, 24px);
  opacity: 0.6;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  flex-direction: row;
  gap: 15px;

  div:empty {
    display: none;
  }

  .subtext_artist {
    overflow: hidden;
    width: 110px;
  }

  .subtext_album {
    overflow: hidden;
    width: 130px;
  }
}
