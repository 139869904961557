@import "../../../../../styles/base";

.oauth_buttons {
  margin-bottom: 19px;
}

.password_container {
  margin-top: 15px;
}

.register_part {
  @include mobile {
    flex-direction: column;
    gap: 20px;
  }

  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.role_part {
  @include font(500, 16px, 24px);
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.radio_row {
  display: flex;
  gap: 40px;
  padding-top: 5px;
}

.sing_title {
  transition: all 1s;
  @include mobile {
    @include font(400, 14px, 21px);
    margin-top: 20px;
  }
  @include font(600, 16px, 24px);
  margin-bottom: 10px;
}

.mob_sign {
  @include font(400, 14px, 21px);
  display: inline-block;
  color: #FF0BE5;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
}

.error {
  margin-top: 16px;
}

hr {
  border: none;
  height: 1px;
  background-color: white;
  margin: 25px 0;
}
