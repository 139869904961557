@import '/src/styles/base';

.container {
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.icon {
  height: 155px;
  width: 155px;
  margin: 0 auto;
}

.title {
  text-align: center;
  @include font(600, 32px, normal)
}

.description {
  text-align: center;
  padding: 0 10%;
  @include font(400, 20px, 28px)
}
