@import "/src/styles/base";

.hidden {
  display: none;
}

.wrapper {
  width: 100px;
  height: 12px;
  border-radius: 8px;
  background: #161650;
}

.progress_color {
  transition: 0.5s;
  height: 100%;
  border-radius: 8px;
}
