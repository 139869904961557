// background colors
$bgrColorDarkBlue: #0F0F36;
$bgrColorLightBlue: #5F5FD3;
$signIn_gradient_clr: linear-gradient(
  240.36deg,
  rgba(69, 196, 249, 0.1) -4.25%,
  rgba(137, 32, 254, 0.1) 43.01%,
  rgba(255, 11, 229, 0.1) 100.2%
);

$main_background_clr: linear-gradient(180deg, #2C116B -45.42%, #0F0F36 59.31%);

// component colors
$link_color: #45C4F9;

// font colors
$main_font_color: #fff;

$border_color: rgba(95, 95, 211, 0.2);

$scrollbar_color: rgba(95, 95, 211, 0.8);
$purple_scrollbar_color: rgba(137, 32, 254, 1);
$scrollbar_hover_color: #5F5FD3;
$scrollbar_background_color: rgba(33, 33, 120, 0.3);

$border_form_color:
        linear-gradient(#0F0F36, #0F0F36) padding-box,
        linear-gradient(240.36deg, #45C4F9 -4.25%, #8920FE 43.01%, #FF0BE5 100.2%) border-box;

$error_color: #FF5C00;

$checkbox_unchecked_color: transparent;
$checkbox_checked_color: #8920FE;

$tooltip_bg_color: #282489;
