@import "src/styles/base";

$button_background: linear-gradient(240.36deg, rgba(69, 196, 249, 0.4) -4.25%, rgba(137, 32, 254, 0.4) 43.01%, rgba(255, 11, 229, 0.4) 100.2%);

.button {
  margin: 40px auto;
}

.button_out {
  @include mobile {
    width: 54px;
    height: 54px;
  }
  @include tablet {
    width: 70px;
    height: 70px;
  }
  display: flex;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  background: $button_background;
  transition: 0.2s;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
}

.button_img {
  @include mobile {
    width: 24px;
    height: 24px;
  }
  margin: auto;
}

.button_text {
  @include mobile {
    @include font(500, 14px, 18px);
    opacity: 0.8;
  }
  @include tablet {
    @include font(400, 16px, 24px);
  }
  margin-top: 8px;
  text-align: center;
}
