@import "src/styles/base";

.container {
  @include mobile {
    height: 24px;
  }

  @include tablet {
    height: 80px;
  }

  width: 100%;
  height: 133px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected_count{
  margin-left: 8px;
  @include font(500, 18px, 21px);
}

.search_field {
  display: flex;
  margin-left: auto;
  width: 285px;
}

.actions_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}