@import "src/styles/base";

@mixin base {
  @include mobile {
    width: 110px;
    height: 24px;
    padding: 4px 16px;
  }
  @include tablet {
    width: 110px;
    height: 24px;
    padding: 4px 15px;
  }
  width: 170px;
  height: 40px;
  display: flex;
  border-radius: 6px;
  outline: none;
  border: none;
  background: linear-gradient(236.5deg, rgba(137, 32, 254, 0.8) 17.25%, rgba(255, 11, 229, 0.8) 128.43%);
  align-items: center;
  justify-content: center;
  padding: 9px 31px;
}

.wrapper {
  position: relative;
  cursor: pointer;

  &:hover {
    .container {
      opacity: 80%;
    }
  }
}

.container {
  @include base;
  opacity: 100%;
  transition: opacity 0.45s;

  &:hover {
    cursor: pointer;
  }
}

.inactive {
  @include base;
  opacity: 50%;
  cursor: not-allowed;
}

.inner_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.text {
  @include tablet {
    @include font(500, 12px, normal);
  }
  @include mobile {
    @include font(500, 12px, normal);
  }
  @include font(500, 16px, normal);
  text-align: center;
  color: white;
  text-transform: uppercase;
}

.image {
  @include mobile {
    width: 14px;
    height: 14px;
  }
  @include tablet {
    width: 14px;
    height: 14px;
  }
  width: 22px;
  height: 22px;
  margin: auto;
}
