@import "src/styles/base";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.header_text {
  @include font(500, 18px, normal);
  margin-left: 20px;
  margin-top: 15px;
}

.recent_transfers {
  height: 100%;
}
