@import "../../../../styles/base";

.horizontal_span {
  @include font(600, 16px, 24px);

  display: flex;
  align-items: center;
  text-align: center;

  margin-bottom: 15px;

  &:before, &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid white;
  }

  &:not(:empty)::before {
    margin-right: 5px;
  }

  &:not(:empty)::after {
    margin-left: 5px;
  }
}
