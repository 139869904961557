@import "src/styles/base";

.list_container {
  overflow: auto;
  max-height: 400px;
  width: 100%;
  height: 100%;
  @include scroller(6px, 0, hidden, auto);

  @include mobile {
    @include scroller(0, 0, hidden, auto);
  }

  @include tablet {
    @include scroller(6px, 0, hidden, auto);
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  padding: 0;

  @include tablet {
    margin-right: 24px;
  }
}

.list_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border_color;
  padding: 10px 0;
  gap: 14px;
}

.item_img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.item_info {
  display: flex;
  flex-direction: column;
  gap: 3px;
  overflow: hidden;
}

.main_info {
  @include font(400, 16px, 24px);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.add_info {
  @include font(400, 16px, 24px);
  opacity: 0.6;
  display: flex;
  flex-direction: row;
  gap: 15px;

  div:empty {
    display: none;
  }
}

.type_info {
  margin-top: 33px;
}

.status_img {
  margin-left: auto;
  margin-right: 15px;
  width: 24px;
  height: 24px;
}
