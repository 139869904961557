@import "src/styles/base";

.wrapper {
    width: 100%;
    height: 100%;
    @include scroller(6px, 0, hidden, hidden);
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.content_box {
    display: flex;
    flex-direction: column;
    width: calc(100% - 285px);

    @include mobile {
        width: 100%;
    }

    @include tablet {
        width: 100%;
    }
}

.actions_header {
    padding-left: 30px;
}

.mob_items {
    margin-top: 15px;
    width: 100%;
    height: 100%;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_container {
    gap: 3px;
}

.title {
    @include font(400, 16px, 24px);
}

.subtext_container {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.add_info {
    @include font(400, 16px, 24px);
    opacity: 0.6;
}