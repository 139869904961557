@import "styles";

.circle {
  @include mobile {
    background-color: $bgrColorDarkBlue;
  }
  height: 150px;
  width: 150px;
  margin: auto auto 30px;
  border-radius: 50%;
  background-color: rgba(22, 22, 80, 1);
}

.icon {
  padding: 30px;
}

.title {
  @include font(600, 32px, 38px);

  @include mobile{
    @include font(600, 20px, 24px);
  }
}

.container {
  @include container();
  @include tablet {
    padding: 60px 69px 30px;
  }
  @include desktop {
    padding: 60px 69px 30px;
  }
}
