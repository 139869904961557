.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.column_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.email_field {
  width: 500px;
}

.row_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.reset_button {
  width: 150px;
  height: 25px;
  text-align: center;
  border-radius: 20px;
  background: #aabc00;
  cursor: pointer;
}

.get_user_button {
  width: 150px;
  height: 25px;
  text-align: center;
  border-radius: 20px;
  background: #45C4F9;
  cursor: pointer;
}
