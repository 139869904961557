@import "src/styles/base";

.dropdown_form {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.option_active {
  visibility: visible;
  opacity: 1;
  padding-inline-start: 0;
  padding-block-start: 5px;
  padding-block-end: 5px;
  margin-block-start: 10px;
  border-radius: 8px;
  background: #282489;
  transition: all .3s ease;
}

.option_inactive {
  visibility: hidden;
  opacity: 0;
  padding-inline-start: 0;
  transition: all .3s ease;
}

ul {
  list-style: none;
}
