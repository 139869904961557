@import "../../../ModalStyleBase.module.scss";

.modal {
    @include desktop {
        width: calc(100vw * 0.65);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include mobile {
        height: 100svh;
        width: 100vw;
    }

    @include vertical_tablet {
        width: calc(100vw * 0.8);
    }
    @include horizontal_tablet {
        width: calc(100vw * 0.8);
    }
}

.header {
    height: 177px;
    background: #2C116B;
    flex-direction: column;
    border-radius: 10px;

    @include mobile {
        height: 140px;
        border-radius: 0;
    }
}

.top_panel {
    display: flex;
    padding: 20px;
    gap: 12px;
}

.first_info_text {
    @include font(500, 16px, 24px);
    margin-top: 3px;
    margin-bottom: 5px;
    text-transform: uppercase;

    @include mobile {
      margin-top: 0;
      margin-bottom: 3px;
    }
}

.second_info_text {
    @include font(400, 16px, 24px);

    @include mobile {
        @include font(400, 14px, 21px);
    }
}

.close_container {
    margin-left: auto;
}

.separate_line {
    border: 1px solid rgba(95, 95, 211, 0.6);
}

.content_wrapper {
    margin-top: -20px;
    background: $modal_background_color;
    border-radius: 10px;
    padding: 20px;
}

.content_row_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .available_text {
        @include font(400, 18px, 27px);
        margin-right: 10px;

        @include mobile {
            @include font(400, 16px, 24px);
        }
    }

    .search_field {
        display: flex;
    }
}

.scrollable_list {
    align-items: center;
    height: calc(100vh * .6);
    overflow-y: scroll;
    padding-right: 10px;

    @include mobile {
        height: calc(100vh * .75);
        padding-right: 0;
    }

    @include vertical_tablet {
        height: calc(100vh * .6);
    }

    @include scroller(6px, 0, hidden, auto);
}
