@import "styles";

.title {
  transition: all 1s;
  @include font(600, 16px, 24px);
  margin-bottom: 15px;
}

.auth_container {
  @include container();
  @include tablet {
    padding: 45px 87px;
  }
  @include desktop {
    padding: 45px 87px;
  }
}
