@import "src/styles/base";

@mixin base_circle {
  @include mobile{
    width: 7px;
    height: 7px;
  }
  position: absolute;
  width: 12px;
  height: 12px;
  right: 0;
  bottom: 0;
  border-radius: 100%;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  @include mobile {
    width: 54px;
    height: 54px;
  }

  position: relative;
  margin: auto;
  border-radius: 20px;
  box-sizing: border-box;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.button_img {
  @include mobile {
    width: 54px;
    height: 54px;
  }
  @include tablet {
    width: 70px;
    height: 70px;
  }
  width: 80px;
  height: 80px;
  box-sizing: border-box;
}

.connected_circle {
  @include base_circle;
  background: #45AE51;
}

.unconnected_circle {
  @include base_circle;
  background: red;
}

.selected_service {
  @include tablet {
    height: 70px;
  }
  height: 80px;
  position: relative;
  box-sizing: border-box;
  margin-right: -8px;
  border-right: 8px #FF00C7 solid;
  border-radius: 0 20px 20px 0;
  transition: opacity 0.3s ease-out;
  animation: appears 0.8s ease-out;
}
@keyframes appears {
  from {
    opacity: 0;}
  to {
    opacity: 1;}
}
