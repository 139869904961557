@import "src/styles/base";

.info {
  @include font(400, 20px, 140%);
  margin-top: 20px;
}

.next_button {
  margin: 24px 0;
}

.email {
  color: $link_color;
}

.line {
  margin: 0 0 32px;
}

.resend_text {
  @include font(400, 18px, 140%);
  opacity: 80%;
}

.resend {
  color: $link_color;
  &:hover {
    cursor: pointer;
    text-decoration-line: underline;
  }
}
