@import "src/styles/base";

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  transition: padding 1s;
  @include mobile {
    padding-top: 15px;
    padding-bottom: 18px;
    height: 56px;
  }

  display: block;
  width: 110px;
  height: 34px;
  margin-left: auto;
  margin-right: auto;
  padding: 28px 24px 30px;
}
