@import "src/styles/base";

.button {
  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 8px 0;
  }
  width: 80%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 10px 5px;
  background-clip: padding-box;
  border-radius: 15px;
  transition: 0.2s;

  &:link {
    text-decoration: none;
    color: inherit;
  }

  &:visited {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    background-color: transparentize(white, 0.9);
    transform: scale(1.05);
  }
}

.button_pending {
  @extend .button;

  .button_image {
    @include mobile {
      width: 25px;
      height: 25px;
    }
    filter: opacity(0.6);
  }

  .button_text {
    @include mobile {
      @include font(500, 14px, 21px);
      margin-top: 5px;
    }
    opacity: 0.6;
  }
}

.button_active {
  @extend .button;

  .button_text {
    @include mobile {
      @include font(500, 16px, 24px);
    }
  }
}

.button_image {
  @include mobile {
    margin-left: 0;
  }
  @include horizontal_tablet {
    margin-left: 0;
  }
  @include vertical_tablet {
    margin-left: 0;
  }
  margin-left: 8px;
  width: 28px;
  height: 28px;
}

.button_text {
  @include font(500, 16px, 24px);
  display: flex;
  align-items: center;
}
