@import "../../../../styles/base";

@mixin container {
  transition: all 1s;

  @include mobile {
    padding: 40px 20px;
  }

  @include tablet {
    background: $border_form_color;
    border: 1px solid transparent;
    border-radius: 20px;

    box-sizing: border-box;
    width: 614px;
  }
  @include desktop {
    background: $border_form_color;
    border: 1px solid transparent;
    border-radius: 20px;

    box-sizing: border-box;
    width: 614px;
  }

  text-align: center;
  margin: auto;
}
