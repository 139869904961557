@import "src/styles/base";

.loader {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.error {
  @include font(600, 32px, 19px);
  color: $error_color;
  text-align: center;
}
