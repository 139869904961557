@import "src/styles/base";
@import "../../ModalStyleBase.module";

.modal {
  @include mobile {
    height: 100vh;
    width: 100vw;
    position: static;
  }

  width: 455px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: $modal_background_gradient_color;
  padding: 30px;
  border-radius: 10px;
}

.close_btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 20px;
  padding: 5px;
  transition: 0.2s;

  &:hover {
    background: transparentize(white, 0.8);
    opacity: 0.8;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.modal_icon_container {
  display: flex;
  justify-content: center;
}

.modal_icon {
  width: 100px;
  height: 100px;
}

.text_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 15px;
}

.title {
  @include font(500, 22px, normal);
  text-transform: uppercase;
}

.description {
  @include font(400, 16px, 22px);
  opacity: 0.8;
}

.services_container {
  img {
    height: 75px;
    width: 75px;
  }

  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;
  gap: 23px;

  .arrow {
    align-self: center;
    height: 32px;
    width: 32px;
  }

  @include mobile() {
    padding-left: 100%;
    padding-right: 100%;
    border-bottom: 1px solid $border_color;
    padding-bottom: 40px;
  }
}

.show_progress {
  width: 278px;
  height: 50px;

  @include mobile() {
    margin-top: 3svh;
  }
}
