@import "src/styles/base";

$button_height: 49px;
$btn_grad: linear-gradient(236.5deg, #8920FE 17.25%, #FF0BE5 128.43%);

@mixin base {
  border-radius: 8px;
  width: 100%;
  height: $button_height;
  border: 0;
  padding: 0;
  color: $main_font_color;
  overflow: hidden;
  outline: none;
}

@keyframes button_gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.btn {
  @include base;
  background: $btn_grad;
  background-size: 150% 150%;
  animation: button_gradient 6s ease infinite -#{random(10)}s;
  opacity: 80%;
  transition: opacity 0.45s;
}

.btn_outline {
  @include base;
  box-sizing: border-box;
  border: 1px solid transparent;
  background: linear-gradient(#1C1C64, #1C1C64) padding-box, $btn_grad border-box;
}

.btn_wrapper {
  @include base;
  @include font(700, 16px, 24px);
  background: $bgrColorDarkBlue;
  position: relative;
  cursor: pointer;

  &:hover {
    .btn {
      opacity: 100%;
    }
  }

  &:focus {
    height: $button_height - 2px;
    border: 1px solid white;
    .btn {
      opacity: 100%;
    }
  }
}

.btn_dis_wrapper {
  @extend .btn_wrapper;
  cursor: not-allowed;

  &:hover {
    .btn {
      opacity: 80%;
    }
  }
}

.btn_title {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
}

.loader {
  @extend .btn_title;
  height: 49px;
}
