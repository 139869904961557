.menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

.icon {
  display: flex;
  width: 30px;
  height: 30px;
}
