@import "src/styles/base";

.container {
  @include mobile {
    min-width: 126px;
    min-height: 120px;
  }

  @include vertical_tablet {
    min-width: 202px;
    min-height: 140px;
  }

  @include horizontal_tablet {
    min-width: 200px;
    min-height: 140px;
  }

  min-width: 270px;
  min-height: 156px;
  background: linear-gradient(#161650, #161650) padding-box, linear-gradient(rgba(95, 95, 211, 0.8), rgba(95, 95, 211, 0.8)) border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 1px;

  &:hover {
    background: linear-gradient(#161650, #161650) padding-box, linear-gradient(240.36deg, #45C4F9 -4.25%, #8920FE 43.01%, #FF0BE5 100.2%) border-box;
    border-width: 2px;
    cursor: pointer;
    margin: 0;
  }
}

.icon {
  width: 240px;
  height: 80px;

  @include mobile {
    width: 120px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 5px;
  }
}
