@import "src/styles/base";

.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.thead {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
}

.headers_row {
  @include font(500, 16px, 24px);
  text-transform: uppercase;
  text-align: left;
  width: 100%;
}

.col_header {
  @include font(500, 16px, 24px);
}

.tbody {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.scroller {
  @include mobile {
    padding-right: 0;
  }

  flex: 1;
  //padding-right: 6px;
  @include scroller(6px, 0, hidden !important, auto, $track_color: transparent);
}

.content_tr {
  @include font(400, 16px, 24px);
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(95, 95, 211, 0.2);
}

.checkbox_container {
  @include mobile {
    margin-left: 0;
  }
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: 30px;
}

.image_container {
  @include mobile {
    margin-left: 10px;
    margin-right: 14px;
  }
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-left: 20px;
  margin-right: 16px;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}


.row_cell {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.row_cell_checkbox {
  @include mobile {
    flex: 0 2 auto !important;
  }
  width: 56px;
  flex: 0 1 auto !important;
}

.row_cell_image {
  width: 78px;
  flex: 0 1 auto !important;
}

.row_cell_title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 25px;
  //flex: 1;
}

.row_cell_tracks {
  //flex: 0 1 auto !important;
}

.header_cell_tracks {
  //flex: 0 1 auto !important;
}

.header_cell_title {
  //flex: 1 !important;
}
