@import "src/styles/base";

$modal_background_color: #0F0F36;
$modal_background_gradient_color:
        linear-gradient(
                        180deg,
                        #2C116B 1.08%,
                        #0F0F36 115.08%);
$modal_background_border_color:
        linear-gradient(
                53.11deg,
                #45C4F9 17.79%,
                #8920FE 41.16%,
                #FF0BE5 69.44%,
                #FFC800 90.96%);

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal {
  animation: fade-in 0.2s ease-in;
}

.modal_fade_out {
  animation: fade-out 0.2s ease-out;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  animation: fade-in 0.2s ease-in;
  
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay_fade_out {
  animation: fade-out 0.2s ease-out;
}


