@import "src/styles/base";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.selected_count{
  @include font(500, 18px, 21px);
}

.actions_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
  padding-bottom: 10px;
}

.text {
  @include font(400, 16px, 24px);
  opacity: 0.8;
}
