@import "src/styles/base";

.wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    gap: 10px;
}

.error{
    @include mobile {
        @include font(600, 40px, 0px);
      }
    @include font(600, 64px, 0px);
}

.page_not_found{
    @include mobile {
        @include font(500, 28px, 0px);
      }
    @include font(500, 36px, 0px);
}

.img{
    @include mobile {
        width: 330px;
        height: 130px;
      }
    width: 522px;
    height: 212px;
}

.btn{
    @include mobile {
        width: 222px;
        margin-top: 10px;
      }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    width: 278px;
    height: 50px;
}