@import "src/styles/base";

.icon_container {
    margin-top: 125px;
}

.info_text {
    @include font(400, 20px, 140%);
    margin-top: 20px;
    margin-bottom: 40px;

    @include mobile {
        @include font(400, 16px, 140%);
        margin-left: 35px;
        margin-right: 35px;
    }
}

.button_container {
    @include mobile {
        margin: 0 40px;
    }

    margin: 0 100px;
}

.line {
    margin: 55px 0 32px;
}