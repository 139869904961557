@import "src/styles/base";

.container {
  @include font(400, 16px, 24px);
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 60px;
  background: transparent;
  border: none;
  color: white;
  border-bottom: 1px solid rgba(95, 95, 211, 0.2);
  outline: none;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    background: transparentize(white, 0.9);
  }
}

.arrow_icon {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.transfer_result_container {
  display: flex;
  padding-right: 20px;
}

.transfer_result_icon {
  width: 30px;
  height: 30px;
}

.service_icon {
  width: 40px;
  height: 40px;
}

.count_text {
  width: 140px;
  display: flex;
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date_text {
  padding: 0 20px;
}

.chevron_img_container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.chevron_img {
  width: 18px;
  height: 18px;
}
