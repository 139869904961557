@import "../ModalStyleBase.module.scss";

.wrapper {
  width: 455px;
  height: 650px;
  border-radius: 10px;
  background-image: $modal_background_gradient_color;
  position: relative;
  
  @include mobile {
    height: 100svh;
    width: 100vw;
    border-radius: 0;
  }
}

.header {
  display: flex;
  gap: 12px;
  padding: 20px;
  border-bottom: 2px solid rgba(95, 95, 211, 0.6);
}

.back_btn {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.close_btn {
  height: 24px;
  width: 24px;
  margin-right: 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.transfer_info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_prev_txt {
  @include font(500, 16px, 24px);
  margin-top: 3px;
  margin-bottom: 5px;
  text-transform: uppercase;

  @include mobile {
    margin-top: 0;
    margin-bottom: 3px;
  }
}

.header_prev_name {
  @include font(400, 16px, 24px);
  margin: 0;

  @include mobile {
    @include font(400, 14px, 21px);
  }
}

.almost_done {
  @include font(500, 22px, 26px);
  text-align: center;
  margin-top: 36px;
}

.check_txt {
  @include font(400, 16px, 24px);

  text-align: center;
  margin-top: 15px;
  opacity: 0.8;
}

.transfer_services {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
  gap: 23px;

  img {
    height: 75px;
    width: 75px;
  }

  .arrow {
    align-self: center;
    height: 32px;
    width: 32px;
  }

  @include mobile {
    padding-left: 100%;
    padding-right: 100%;
    border-bottom: 1px solid $border_color;
    padding-bottom: 40px;
  }
}

.items {
  height: 200px;
  width: 400px;
  margin-left: auto;
  @include scroller(6px, 0, hidden, auto);

  @include mobile {
    height: 30vh;
    width: 90vw;
    margin-left: 0;
  }
}

.transfer_btn {
  width: 278px;
  height: 50px;
  margin-top: 20px;

  @include mobile {
    margin-top: 3svh;
  }
}
