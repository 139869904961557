@import "src/styles/base";

.container {
  @include mobile {
    margin-left: 0;
    gap: 6px;
  }

  @include tablet {
    gap: 6px;
  }

  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 30px;
}