@import "/src/styles/base";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.header {
  margin-left: 30px;
}

.title_container {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: 0.09s;
}

.status_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.media_item_type {
  &::first-letter {
    text-transform: uppercase;
  }
}

.service_image_container {
  display: flex;
  align-items: center;
}

.service_image {
  width: 100px;
  height: 30px;
  object-fit: cover;
}

.user_data_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}

.user_avatar {
  width: 30px;
  height: 30px;
}

.user_email {
  @include font(400, 16px, 24px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.widgets_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 133px);
}

.widgets_container_single {
  @extend .widgets_container;
  height: 100%;
}

.active_wrapper {
  display: flex;
  width: 100%;
  height: 50%;
}

.recent_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.recent_wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 15px;
}

.recent_header {
  @include font(500, 18px, normal);
  margin-left: 20px;
}
