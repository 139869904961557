@import "src/styles/base";

.dropdownItem {
  display: flex;
  border-bottom: 1px solid rgba(95, 95, 211, 0.2);
  margin-left: 10px;
  margin-right: 10px;
  &:last-child {
    border-bottom: none;
  }
}

.item {
  @include horizontal_tablet {
    @include font(400, 14px, 21px);
    padding: 7px;
  }
  @include vertical_tablet {
    @include font(400, 14px, 21px);
    padding: 7px;
  }
  @include font(500, 16px, 24px);
  transition: 0.3s;
  width: 100%;
  text-decoration: none;
  color: inherit;
  opacity: 0.8;
  display: block;
  padding: 10px;

  &:hover {
    border-radius: 20px;
    background-color: transparentize(white, 0.9);
    opacity: 1;
  }
}
