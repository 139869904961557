@import "src/styles/base";

.info{
    margin-top: 20px;

    @include font(400, 20px, 28px);

    @include mobile{
        @include font(400, 15px, 28px);
    }
}

.to_home_btn{
    margin-top: 55px;
    margin-bottom: 86px;

    @include mobile{
        margin-bottom: 0px;
    }
}