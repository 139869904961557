@import "src/styles/base";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.data_grid {
  height: 100%;
  margin-top: 20px;
}

.service_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.service_image_container {
  display: flex;
  align-items: center;
}

.service_image {
  width: 100px;
  height: 30px;
  user-select: none;
}

.user_data_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}

.user_avatar {
  width: 30px;
  height: 30px;
  user-select: none;
}

.user_email {
  @include font(400, 16px, 24px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.status_icon {
  display: flex;
  user-select: none;
}
