@import "src/styles/base";

.circle {
  @include horizontal_tablet {
    width: 32px;
    height: 32px;
  }
  @include vertical_tablet {
    width: 32px;
    height: 32px;
  }
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #1C1C64;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: transparentize(white, 0.9);
  }
}

.circle_inactive {
  @extend .circle;
  cursor: default;
  background: #17174F;

  &:hover {
    background-color: #17174F;
  }
}

.image {
  @include tablet {
    width: 22px;
    height: 22px;
  }

  width: 35px;
  height: 35px;
}
