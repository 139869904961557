@import "src/styles/base";

.reset_password_container{
    margin-top: 30px;
}

.reset_btn{
    margin-top: 40px;
    padding-bottom: 86px;

    @include mobile{
        padding-bottom: 0;
    }
}

.confirm_input{
    margin-top: 15px;
}
