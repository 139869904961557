@import "/src/styles/base";

.container {
  width: 100%;
  height: 100%;
}

.data_container {
  width: 100%;
  height: 100%;
}

.mob_grid_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}

.main_content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.text_container {
  @include font(400, 16px, 24px);
  width: 100%;
  gap: 3px;
}

.subtext_container {
  @include font(400, 14px, normal);
  display: flex;
  width: 100%;
  gap: 25px;
}

.subtext_text {
  display: flex;
  opacity: 0.6;
  gap: 10px;
}

.progress_text {
  @include font(600, 14px, normal);
  opacity: 1;
  margin-left: auto;
  display: flex;
}

.logo_container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-left: auto;
}

.service_logo {
  width: 50px;
  height: 15px;
  object-fit: cover;
}

.arrow_icon {
  width: 12px;
  height: 8px;
  object-fit: cover;
}
