@use 'sass:math';
@import "colors";
@import "media-bp";

@mixin font($weight, $size, $height, $style: normal) {
  font-family: 'Rubik', sans-serif;
  font-weight: $weight;
  font-size: math.div($size, 16px) * 1rem;
  line-height: $height;
  font-style: $style;
}

@mixin scroller($width, $height, $x, $y, $track_color: $scrollbar_background_color) {
  overflow-x: $x;
  overflow-y: $y;
  scrollbar-width: thin;
  scrollbar-color: #5F5FD3CC #2121784D;

  &::-webkit-scrollbar {
    @include mobile {
      width: 0;
      height: 0;
    }
    @include horizontal_tablet {
      width: 0;
      height: 0;
    }
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    background: $track_color;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar_color;
    border-radius: 20px;

    &:hover {
      background: $scrollbar_hover_color;
    }
  }
}

.tooltip {
  background: $tooltip_bg_color !important;
}
