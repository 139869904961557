@import "../../ModalStyleBase.module";

.trial_version_wrapper {
    width: 600px;
    height: 420px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 80px rgba(12, 12, 12, 0.3);
    background-image: $modal_background_border_color;

    @include mobile {
        width: 100vw;
        height: 100vh;
    }
}

.trial_version_border {
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 10px;
    background: $modal_background_gradient_color padding-box, $modal_background_border_color border-box;

    @include mobile {
        border-radius: 0;
        background: $modal_background_gradient_color
    }
}

.close_btn {
    height: 24px;
    width: 24px;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
    position: absolute;
    right: 0;

    @include mobile {
        width: 22px;
        height: 22px;
    }
}

.main_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;

    @include mobile {
        margin-top: 110px;
        margin-bottom: 105px;
    }
}

.thank_you_text {
    @include font(500, 18px, 120%);
}

.have_transferred_text {
    @include font(400, 16px, 24px);
    margin-top: 20px;
}

.transfer_boxes {
    display: flex;
    margin: 25px 0;
}

.trial_version_allow_text {
    @include font(400, 16px, 24px);
}

.you_have_reached_text {
    margin-top: 10px;
    @include font(400, 16px, 150%);
}

.premium_btn_container {
    margin-left: 30px;
    margin-top: 30px;

    @include mobile {
        margin-top: calc(100vh * 0.34);
    }
}
