@import "src/styles/base";
.progress_bar {
    width: 146px;
    height: 20px;
    background: #1C1C64;

    @include mobile {
        width: 100px;
    }

    .progress_color {
        transition: 0.5s;
        background: linear-gradient(240.36deg, #45C4F9 -4.25%, #8920FE 43.01%, #FF0BE5 100.2%);
        height: 100%;
    }
}