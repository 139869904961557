@import "src/styles/base";

.container {
  min-width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.image {
  position: absolute;
  border-radius: 6px;
}

.text_container {
  @include font(700, 14px, 21px);
  width: 175px;
  height: 32px;
  position: absolute;
  background: #8920FE;
  border-radius: 0 5px 5px 0;
  margin-bottom: 20px;
}

.text {
  margin-left: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
}
