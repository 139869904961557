//@import "/src/styles/base";
//
.blank_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
//
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
//
//.container {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  gap: 30px;
//}
//
//.header {
//  margin-left: 30px;
//}
//
//.title_container {
//  overflow: hidden;
//  text-overflow: ellipsis;
//  cursor: pointer;
//  transition: 0.09s;
//
//  /*&:hover {
//    background: transparentize(white, 0.9);
//  }*/
//}
//
//.status_wrapper {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  gap: 6px;
//}
//
//.media_item_type {
//  &::first-letter {
//    text-transform: uppercase;
//  }
//}
//
//.service_image_container {
//  display: flex;
//  align-items: center;
//}
//
//.service_image {
//  width: 100px;
//  height: 30px;
//  object-fit: cover;
//}
//
//.user_data_container {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  gap: 5px;
//  text-overflow: ellipsis;
//  overflow: hidden;
//  padding-right: 10px;
//}
//
//.user_avatar {
//  width: 30px;
//  height: 30px;
//}
//
//.user_email {
//  @include font(400, 16px, 24px);
//  text-overflow: ellipsis;
//  overflow: hidden;
//}
