@import "src/styles/base";

.container {
  display: flex;
  width: 100%;
  height: 334px;
  background: rgba(15, 15, 54, 0.4);
  border: 1px solid rgba(95, 95, 211, 0.5);
  border-radius: 20px;
}

.inner_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 30px 24px;
  overflow-y: hidden;
}

.header_text {
  @include font(500, 18px, 21px);
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 29px;
}

.see_all {
  @include font(500, 18px, 21px);
  background: inherit;
  border: 2px solid transparent;
  outline: none;
  border-radius: 10px;
  color: white;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    background: transparentize(white, 0.9);
  }
}

.scroller {
  @include scroller(6px, 0, hidden, auto);
}

.recent_transfers_container {
  display: flex;
  flex-direction: column;
  padding-right: 7px;
}
