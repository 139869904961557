@import "src/styles/base";

.container {
  @include mobile {
    min-width: 165px;
    height: 100px;
  }
  height: 156px;
  min-width: 294px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  background: linear-gradient(#161650, #161650) padding-box,
  linear-gradient(201.32deg, rgba(69, 196, 249) -24.51%, rgba(137, 32, 254) 66.66%, rgba(255, 11, 229) 141.36%) border-box;

  &:hover {
    background: linear-gradient(240.36deg,
            rgba(69, 196, 249, 0.2) -4.25%,
            rgba(137, 32, 254, 0.2) 43.01%,
            rgba(255, 11, 229, 0.2) 100.2%);
    border: 1px solid #b319f5;
  }
}

.service_icon {
  width: 120px;
  height: 40px;
  margin-bottom: 10px;
  
  @include mobile {
    margin-bottom: 5px;
  }
}

.email_text {
  @include mobile {
    @include font(400, 14px, 21px);
    width: 114px;
  }
  @include font(400, 16px, 24px);
  width: 188px;
  text-align: center;
  overflow: hidden;
  color: white;
}
