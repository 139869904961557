@import "../../../../../styles/base";

.button {
  @include mobile {
    height: 42px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #FFFFFF;
  border-radius: 8px;
  width: 100%;
  height: 49px;

  background: rgba(255, 255, 255,0);
  cursor:pointer;
  overflow: hidden;
  transition: all 0.75s ease;

  @include font(500, 16px, 24px);
  color: white;
  text-transform: uppercase;

  &:hover, &:focus {
    background: white;
    color: black;
  }
}
