@import "src/styles/base";

.box {
    width: 70px;
    height: 70px;
    background: rgba(255, 255, 255, 0.34);
    border-radius: 15px;
    text-align: center;
    margin: 0 7px;
}

.count_text {
    margin-top: 15px;
    @include font(400, 16px, 24px);
}

.type_text {
    margin-top: 12px;
    @include font(400, 14px, 21px);
}