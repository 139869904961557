@import "../../ModalStyleBase.module.scss";

.modal {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  @include desktop {
    width: calc(100% * 0.375);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include mobile {
    height: 100svh;
    width: 100vw;
  }

  @include vertical_tablet {
    width: calc(100vw * 0.8);
  }
}

.header {
  background: #2C116B;
  flex-direction: column;

  @include mobile {
    border-radius: 0;
  }
}

.up_header {
  display: flex;
  padding: 20px;
  gap: 12px;
}

.transfer_text {
  @include font(500, 16px, 24px);
  margin-top: 3px;
  margin-bottom: 5px;
  text-transform: uppercase;

  @include mobile {
    margin-top: 0;
    margin-bottom: 3px;
  }
}

.select_service_text {
  @include font(400, 16px, 24px);

  @include mobile {
    @include font(400, 14px, 21px);
  }
}

.close_container {
  margin-left: auto;
}

.separate_line {
  border: 1px solid rgba(95, 95, 211, 0.6);
}

.service_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px 30px;
}

.source_service_text {
  @include font(400, 16px, 24px);
}

.source_service_logo {
  width: 120px;
  height: 40px;
  margin: 5px 0;
}

.email_service_text {
  @include font(400, 16px, 24px);
}

.content_wrapper {
  background: $modal_background_color;
  padding: 20px;
}

.content_row_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.select_destination_text {
  @include font(400, 18px, 27px);
  margin-right: 10px;

  @include mobile {
    @include font(400, 16px, 24px);
  }
}

.search_field {
  display: flex;
}

.scrollable_list {
  align-items: center;
  height: calc(100vh * .45);
  overflow-y: scroll;
  padding-right: 10px;

  @include mobile {
    height: calc(100vh * .7);
    padding-right: 0;
  }

  @include vertical_tablet {
    height: calc(100vh * .6);
  }

  @include scroller(6px, 0, hidden, auto);
}
