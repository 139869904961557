@import "../../../ModalStyleBase.module.scss";

$btn_background_color: #0F0F36;
$selected_btn_background_color: #8920FE;

.modal {
    background: $modal_background_gradient_color;
    border-radius: 10px;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include desktop {
        width: 1000px;
        height: 686px;
    }

    @include mobile {
        width: 100vw;
        height: 100vh;
    }

}

.playlist_title {
    padding-top: 30px;
    text-align: center;

    @include font(500, 18px, 21px);

    @include mobile {
        padding-top: 10px;
    }
}

.back_btn {
    width: 22px;
    height: 22px;
    margin-top: 15px;
    margin-left: 20px;
}

.close_btn {
    height: 24px;
    width: 24px;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
    position: absolute;
    right: 0;

    @include mobile {
        margin-top: 15px;
        width: 22px;
        height: 22px;
    }
}

.buttons_container {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    margin-bottom: 20px;
    justify-content: center;
}

.button {
    @include font(500, 16px, 24px);
    display: flex;
    color: $main_font_color;
    width: 125px;
    height: 39px;
    background: $btn_background_color;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.25s ease;

    &:hover {
        background: $selected_btn_background_color;
    }
}

.selected {
    background: $selected_btn_background_color;
}

.data_grid {
    height: 500px;

    @include mobile {
        height: 65%;
    }
}

.text_container {
    @include font(400, 16px, 24px);
    gap: 3px;
}

.subtext_container {
    display: flex;
    gap: 10px;
    opacity: 0.6;
}