@import "../../../../../styles/base";

.oauth_buttons {
  margin-bottom: 19px;
}

.password_container {
  margin-top: 15px;
}

.forgot_password {
  transition: all 0.2s;
  @include font(400, 16px, 24px);
  display: inline-block;
  cursor: pointer;
  text-align: left;
  text-decoration-line: underline;
  margin: auto 0;
  color: inherit;

  &:hover {
    color: $link_color;
  }

  &:focus {
    color: $link_color;
  }
}

.login_part {
  @include mobile {
    flex-direction: column;
    gap: 20px;
  }

  @include tablet {
    div:last-child  {
      width: 200px;
    }
  }

  @include desktop {
    div:last-child  {
      width: 200px;
    }
  }

  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.sing_title {
  transition: all 1s;
  @include mobile {
    @include font(400, 14px, 21px);
    margin-top: 20px;
  }
  @include font(600, 16px, 24px);
  margin-bottom: 10px;
}

.mob_sign {
  @include font(400, 14px, 21px);
  display: inline-block;
  color: #FF0BE5;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
}

.error {
  margin-top: 16px;
}

hr {
  border: none;
  height: 1px;
  background-color: white;
  margin: 25px 0;
}
