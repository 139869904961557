@import "src/styles/base";
@import "../../features/main-page/components/sidebar/styles/SideBarForm.module";

$mainpage_background: linear-gradient(240.36deg, rgba(69, 196, 249, 0.06) -4.25%, rgba(137, 32, 254, 0.06) 43.01%, rgba(255, 11, 229, 0.06) 100.2%);
$main_margin_top_desktop: 20px;
$main_margin_left_desktop: 30px;
$main_margin_right_desktop: 30px;
$main_margin_left_mobile: 20px;
$main_margin_right_mobile: 20px;
$main_margin_top_mobile: 10px;

$desktop_main_width: calc(100% - $sidebar_width - ($main_margin_left_desktop + $main_margin_right_desktop));
$mobile_main_width: calc(100% - ($main_margin_left_mobile + $main_margin_right_mobile));
$horizontal_tablet_main_width: calc(100% - $tablet_sidebar_width - ($main_margin_left_mobile + $main_margin_right_mobile));
$vertical_tablet_main_width: calc(100% - ($main_margin_left_desktop + $main_margin_right_desktop));

.main {
  background: $mainpage_background;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.column {
  @include mobile {
    width: $mobile_main_width;
    height: calc(100vh - $main_margin_top_mobile);
    margin-left: $main_margin_left_mobile;
    margin-right: $main_margin_right_mobile;
    margin-top: $main_margin_top_mobile;
  }
  @include horizontal_tablet {
    width: $horizontal_tablet_main_width;
    height: calc(100vh - $main_margin_top_desktop);
    margin-left: $tablet_sidebar_width;
    margin-right: $main_margin_right_mobile;
  }
  @include vertical_tablet {
    width: $vertical_tablet_main_width;
    height: calc(100vh - $main_margin_top_desktop);
    margin-left: $main_margin_left_desktop;
    margin-right: $main_margin_right_desktop;
  }
  width: $desktop_main_width;
  height: calc(100vh - $main_margin_top_desktop);
  display: flex;
  flex-direction: column;
  margin-left: $sidebar_width;
  margin-right: $main_margin_right_desktop;
  margin-top: $main_margin_top_desktop;
}

.header {
  @include mobile {
    border: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
  }
  @include horizontal_tablet {
    padding-left: $main_margin_left_mobile;
    padding-right: $main_margin_right_mobile;
  }
  @include vertical_tablet {
    padding-left: 0;
    padding-right: 0;
  }
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  z-index: 1;
  padding-left: $main_margin_left_desktop;
  padding-right: $main_margin_right_desktop;
  padding-bottom: 19px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.buttons_container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.outlet {
  @include mobile {
    margin: 0;
    padding-right: 0;
  }

  @include horizontal_tablet {
    margin-left: $main_margin_left_mobile;
    margin-right: $main_margin_right_mobile;
    padding-right: 0;
  }

  @include vertical_tablet {
    margin: 0;
    padding: 0;
  }

  width: 100%;
  margin-left: $main_margin_left_desktop;
  margin-right: $main_margin_right_desktop;
  position: relative;
  display: flex;
  flex: 1;
  padding-right: 30px;

  @include scroller(6px, 0, hidden, auto);
}
