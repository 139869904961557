@import "src/styles/base";

.icon_container {
  @include mobile {
    margin: 5vh 0 0;
  }

  height: 150px;
  width: 150px;
  margin: auto auto 30px;
  border-radius: 50%;
  background-color: rgba(22, 22, 80, 1);
}

.icon {
  padding: 30px;
}

.container {
  @include mobile {
    height: 100svh;
    width: 100vw;
    padding: 0;
  }
  position: relative;
  width: 600px;
  background: #1C1C64;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 53px 89px 53px;
}

.title {
  margin-top: 30px;
  @include font(600, 32px, 38px);
  text-align: center;
}

.description {
  width: 100%;
  margin-top: 40px;
  @include font(400, 20px, 140%);
  text-align: center;
}

.button {
  @include mobile {
    margin: 0;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    width: 75vw;
  }
  margin-top: 80px;
  width: 330px;
}

.close_btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 20px;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.25);
  }
}
