@import "src/styles/base";
@import "SideBarForm.module";

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.sidebar_wrapper {
  @include tablet {
    width: $tablet_sidebar_width;
  }
  position: fixed;
  height: 100%;
  width: $mobile_sidebar_width;
  background-color: $bgrColorDarkBlue;
  box-sizing: border-box;
}

.modal {
  animation: fade-in 0.2s ease-in;
}

.modal_fade_out {
  animation: fade-out 0.2s ease-out;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  animation: fade-in 0.2s ease-in;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
