@import "src/styles/base";

.container {
  @include tablet {
    gap: 9px;
  }
  display: flex;
  flex-direction: column;
  gap: 15px;
}
