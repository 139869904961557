@import "../../../../../styles/base";

.button {
  @include mobile {
    height: 42px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  //width: 100%;
  //: 49px;

  overflow: hidden;
  transition: all 0.75s ease;

  @include font(500, 16px, 24px);
  color: white;
  text-transform: uppercase;
}

.icon {
  padding-right: 20px;
}
