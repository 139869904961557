@import "src/styles/base";

.container {
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: 30px;
  margin-bottom: 15px;
}

.field {
  @include font(400, 16px, 24px);
  width: 100%;
  border: 0;
  border-radius: 4px;
  outline: 0;
  background: #282489 url(../../../../assets/search-icon.svg) no-repeat right 5% center;
  color: white;
  padding-left: 10px;
  margin: 0 auto;

  &::placeholder {
    @include font(400, 16px, 24px);
    color: white;
    opacity: 0.7;
  }
}
