@import "src/styles/base";

.iconButton {
  @include mobile {
    height: 22px;
    width: 22px;
  }

  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: none;
  cursor: pointer;
}

.icon {
  @include mobile {
    height: 22px;
    width: 22px;
  }
  height: 24px;
  width: 24px;
}