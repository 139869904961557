@import "../../ModalStyleBase.module.scss";

.transfer_process_wrapper {

  @include mobile {
    height: 100svh;
    width: 100vw;
    position: static;
    border-radius: 0;
  }

  width: 455px;
  height: 400px;
  position: relative;
  border-radius: 10px;
  background-image: $modal_background_gradient_color;
}

.hide_btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 20px;
}

.transfer_text {
  @include font(500, 22px, 26px);
  margin-top: 40px;
}

.transfer_speed_text {
  @include font(400, 16px, 24px);

  text-align: center;
  margin-top: 15px;
  opacity: 0.8;
}

.transfer_header_info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.transfer_services {
  display: flex;
  align-items: center;
  margin-top: 30px;

  img {
    height: 75px;
    width: 75px;
  }
}

.progress_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7px;

  .percent_text {
    @include font(500, 16px, 24px);
    margin-bottom: 10px;
  }

  .transfer_status_text {
    @include mobile {
      width: 80vw;
    }
    @include font(400, 16px, 24px);
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 24px;
    text-align: center;
    display: block;
  }
}

.horizontal_span {
  width: 100%;
  height: 1px;
  background: rgba(95, 95, 211, 0.6);
  margin: 20px 0;

  @include mobile {
    margin: 15px 0;
  }
}

.transfer_info {
  margin-left: 12px;
}

.items_counter {
  display: flex;
  align-self: flex-start;
  margin-bottom: 5px;
  @include font(500, 16px, 24px);

  .transferred_count {
    color: #B014D2;
  }
}

.items {
  height: 205px;
  width: 360px;

  @include mobile {
    height: 40vh;
    width: 90vw;
    margin-bottom: 20px;
  }

  margin-bottom: 40px;
}

.cancel_button {
  margin-top: 35px;
}
