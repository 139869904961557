@import "src/styles/base";

.item_header {
  display: flex;
  gap: 20px;
  padding: 20px 0 0 30px;
  transition: height 1s ease-in-out, opacity 0.3s ease-in-out;

  @include mobile {
    padding: 0;
    gap: 10px;
  }
}

.image_container {
  position: relative;
}

.header_image {
  width: 245px;
  height: 245px;
  aspect-ratio: 1 / 1;
  border-radius: 25px;

  @include mobile {
    width: 130px;
    height: 130px;
    border-radius: 12px;
  }
}

.collapse_button {
  background: #8920FE;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.collapsed {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.expanded {
  height: auto;
  opacity: 1;
  //transition-delay: 0.3s;
}

.item_type_text {
  @include font(400, 16px, 24px);
  text-transform: capitalize;

  @include mobile {
    @include font(400, 14px, 21px);
  }
}

.item_title_text {
  @include font(500, 48px, 72px);
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  width: 900px;

  @include mobile {
    @include font(500, 20px, 24px);
    margin-top: 5px;
    width: 200px;
  }
}

.service_logo {
  margin-top: 15px;
  width: 100px;
  height: 37px;

  @include mobile {
    width: 75px;
    height: 27px;
    margin-top: 15px;
  }
}

.item_description {
  @include font(400, 16px, 24px);
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  width: 900px;

  @include mobile {
    @include font(400, 14px, 21px);
    width: 200px;
  }
}

.item_additional_info {
  display: flex;
  gap: 15px;
  margin-top: 10px;

  .info_count {
    @include font(500, 16px, 24px);
    display: flex;
    gap: 5px;
    align-items: center;

    @include mobile {
      @include font(400, 14px, 21px);
      opacity: 0.8;
      gap: 2px;
    }

    img {
      width: 20px;
      height: 20px;

      @include mobile {
        width: 12px;
        height: 12px;
      }
    }
  }
}
