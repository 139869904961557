@import "../../../ModalStyleBase.module.scss";

.modal {
    background: $modal_background_gradient_color;
    border-radius: 10px;
    align-items: center;
    position: relative;
    justify-content: center;

    @include desktop {
        width: 1000px;
        height: 686px;
    }

    @include mobile {
        width: 100vw;
        height: 100vh;
    }
}

.top_header {
    display: flex;
    justify-content: space-between;

    @include mobile {
        display: block;
        justify-content: space-between;
    }
}

.left_header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 20px;
    padding-top: 20px;

    .back_icon {
        height: 24px;
        width: 24px;
        cursor: pointer;

        @include mobile {
            height: 22px;
            width: 22px;
        }
    }

    .active_transfer_text {
        @include font(500, 16px, 24px);
        text-transform: uppercase;
        margin-right: 10px;

        @include mobile {
            @include font(500, 14px, 21px);
        }
    }

}

.playlist_title {
    @include font(500, 18px, 21px);
    text-align: center;
    @include desktop {
        margin-right: 65px;
    }
}

.right_header {
    display: flex;
    gap: 30px;
    margin-top: 25px;
    margin-right: 75px;
    align-items: center;
    user-select: none;
    @include mobile {
        gap: 0px;
        padding: 0 30px;
        margin-right: 0px;
        justify-content: space-between;
    }

    .source_logo {
        width: 100px;
        height: 30px;
    }

    .destination_logo {
        width: 100px;
        height: 30px;
    }

    .arrow_img {
        width: 32px;
        height: 32px;
    }
}

.close_icon {
    height: 24px;
    width: 24px;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
    position: absolute;
    right: 0;

    @include mobile {
        margin-top: 0;
        width: 22px;
        height: 22px;
    }
}

.data_grid {
    height: 500px;
    @include mobile {
        height: 80vh;
    }
}

.mob_grid_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;


    .text_container {
        @include font(400, 16px, 24px);
        gap: 3px;

        .subtext_container {
            display: flex;
            gap: 10px;
            opacity: 0.6;
        }
    }
}