@import "src/styles/base";

.checkbox {
    width: 24px;
    height: 24px;
    display:  inline-block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;

    border: 2px solid white;
    background-color: $checkbox_unchecked_color;
    transition: all 0.3s ease;
    border-radius: 4px;
    box-sizing: border-box;

    &:checked {
        border: none;
        background-color: $checkbox_checked_color;
        border-radius: 4px;

        &:before {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background-size: 15px 15px;
            background-image: url('../../assets/checkbox-tick.svg');
            border-width: 0 2px 2px 0;
            margin: 4px 0 0 4px;
        }
    }

    &:active {
        transform: scale(0.95);
    }
}
