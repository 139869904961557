@mixin desktop {
  @media (min-width: 1100px) {
    @content;
  }
}

@mixin vertical_tablet {
  @media (min-width: 767px) and (max-width: 1000px) {
    @content;
  }
}

@mixin horizontal_tablet {
  @media (min-width: 1001px) and (max-width: 1099px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 767px) and (max-width: 1099px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 766px) {
    @content;
  }
}
