@import "../../../../styles/base";

.header {
  @include tablet {
    border-bottom: 1px solid $border_color;
  }
}

.logo {
  transition: padding 1s;
  @include mobile {
    padding-top: 20px;
  }

  @include tablet {
    padding-bottom: 30px;
  }

  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}
