@import "src/styles/base";

.container {
  @include mobile {
    width: 24px;
    height: 24px;
  }
  @include tablet {
    width: 24px;
    height: 24px;
  }
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(137, 32, 254, 0.4);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.4s;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    background: #8920FE;
  }
}

.icon {
  @include mobile {
    width: 15px;
    height: 15px;
  }
  @include tablet {
    width: 15px;
    height: 15px;
  }
  width: 24px;
  height: 24px;
  margin: auto;
}
