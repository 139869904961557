@import "/src/styles/base";

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.title_container {
  @include font(400, 14px, normal);
  width: 120px;
  display: flex;
  overflow: hidden;
}

.date_container {
  @include font(400, 14px, normal);
}

.type {
  @include font(400, 14px, normal);
}

.service_img {
  display: flex;
  width: 100px;
  height: 30px;
  object-fit: cover;
}
