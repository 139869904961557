@import "src/styles/base";


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 30px;
  text-align: center;
}

.loader {
  width: 300px;
  height: 300px;
}

.header_text {
  @include font(600, 32px, 38px);
}

.sub_text {
  @include font(400, 20px, 28px);
}
