@import "src/styles/base";

$emoji_selected_color: #5F5FD3;

.btn {
    width: 61px;
    height: 61px;
    border-radius: 30px;
    background: rgba(95, 95, 211, 0.2);
    border: none;
    outline: none;
    margin: 0 4px;
    transition: 0.5s;

    &:hover, &:focus {
         background: $emoji_selected_color;
         transform: scale(1.1);
    }
}