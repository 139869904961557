.dropdown {
  width: 150px;
  height: 25px;
}

.dropdown_item {
  height: 25px;
}

.submit {
  width: 150px;
  height: 25px;
  background: green;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
}
