@import "src/styles/base";

.column {
  @include tablet {
    gap: 10px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 7.5px 0;
}
