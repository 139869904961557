@import '../../../../ModalStyleBase.module.scss';

.grid_tiles {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));

    @include vertical_tablet {
      gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    }

    @include mobile {
        gap: 15px;
        grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    }
}