@import "src/styles/base";

.inp_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inp {
  background: #1B114A;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  outline: none;

  transition: border 0.3s;

  @include font(400, 16px, 150%);
  padding-left: 20px;
  padding-right: 50px;

  color: white;

  &:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 31px #1B114A inset !important;
    -webkit-text-fill-color: white;
  }
  &:-webkit-autofill::first-line {
    @include font(400, 16px, 150%);
  }
}

.inp_invalid {
  border: 1px solid $error_color;
}

.inp_valid {
  border: 1px solid rgba(95, 95, 211, 0.5);

  &:focus {
    border: 1px solid #9747FF;
  }
}

.eye_button {
  position: absolute;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 12px;

  &:hover {
    cursor: pointer;
  }
}

.eye_icon {
  width: 24px;
  height: 24px;
}

.title {
  @include font(500, 16px, 150%);
  text-align: left;
  opacity: 60%;

  margin-bottom: 5px;
}

.invalid_text {
  margin-top: 5px;
  transition: all 1s;
  color: $error_color;
  text-align: left;
  @include font(400, 16px, 150%);
}
