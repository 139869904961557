@import "src/styles/base";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  @include mobile {
    width: 54px;
    height: 54px;
  }

  position: relative;
  margin: auto;
  border-radius: 20px;
  box-sizing: border-box;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.button_img {
  @include mobile {
    width: 54px;
    height: 54px;
  }
  @include tablet {
    width: 70px;
    height: 70px;
  }
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background: linear-gradient(240deg, rgba(69, 196, 249, 0.40) -4.25%, rgba(137, 32, 254, 0.40) 43.01%, rgba(255, 11, 229, 0.40) 100.2%);
  box-sizing: border-box;
}
