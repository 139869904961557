@import "src/styles/base";

.container {
  display: flex;
  gap: 10px;
  flex-direction: row;
  text-align: center;
  align-items: center;
}


.text_container {
  display: flex;
}

.text {
  @include mobile {
    @include font(600, 16px, 24px);
  }

  @include horizontal_tablet {
    @include font(500, 16px, 24px);
  }

  @include vertical_tablet {
    @include font(500, 16px, 24px);
  }

  @include font(600, 24px, 28px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 10px;
  align-items: center;
}

.license_text {
  @include mobile {
    @include font(400, 16px, 24px);
  }

  @include horizontal_tablet {
    @include font(400, 16px, 24px);
  }

  @include vertical_tablet {
    @include font(400, 16px, 24px);
  }

  @include font(400, 18px, 21px);

  text-transform: capitalize;
  margin-left: 5px;
}