@import "src/styles/base";

.wrapper {
  width: 100%;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.content_box {
  @include horizontal_tablet {
    width: calc(100% - 186px);
  }

  @include vertical_tablet {
    width: 100%;
  }

  width: calc(100% - 285px);
  display: flex;
  flex-direction: column;
}

.playlists_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 30px;
}

.subtext_container {
  @include font(400, 16px, 24px);
  opacity: 0.6;
  display: flex;
  flex-direction: row;
  gap: 35px;
  overflow: hidden;
  white-space: nowrap;

  div:empty {
    display: none;
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track_title_href {
  text-decoration: none;
  color: inherit;
}