@import "src/styles/base";

.header {
  transition: margin-bottom 1s;
  @include mobile {
    margin-bottom: 0px;
  }

  margin-bottom: 80px;
}
