@import "../../../../../styles/base";

.radio_circle {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #0F0F36;
  padding-right: 8px;
  background-color: transparent;
  margin-right: 8px;

  &:hover {
    transform: scale(1.05);
    background-color: transparentize(white, 0.9);
    transition: 0.2s;
  }

  &:checked {
    border: 5px solid #821dcd;
    background-color: #ffffff;
  }
}

.radio_text {
  display: flex;
  justify-content: center;
  cursor: pointer;
  @include font(400, 16px, 150%);
}
