@import "src/styles/base";

.button {
    min-width: 200px;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(#161650, #161650) padding-box,
      linear-gradient(rgba(95, 95, 211, 0.8), rgba(95, 95, 211, 0.8)) border-box;
    border: 1px solid transparent;
    border-radius: 10px;

    // Set margin 1px and remove on hover to prevent buttons from twitching on hover
    margin: 1px;

    &:hover {
        background: linear-gradient(#161650, #161650) padding-box, linear-gradient(240.36deg, #45C4F9 -4.25%, #8920FE 43.01%, #FF0BE5 100.2%) border-box;
        border-width: 2px;
        cursor: pointer;
        margin: 0;
    }

    @include mobile {
        min-width: 126px;
        min-height: 120px;
    }
}

.button_img {
    width: 120px;
    height: 40px;
    margin-top: -20px;
    margin-bottom: 10px;

    @include mobile {
        margin-top: 0px;
        margin-bottom: 5px;
    }
}

.email_text {
    width: 150px;
    text-align: center;
    overflow: hidden;

    @include font(400, 16px, 24px);

    @include mobile {
        @include font(400, 14px, 21px);
        width: 130px;
    }
}

.connected_container {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-top: 10px;
    margin-bottom: -30px;

    @include mobile {
        margin-top: 5px;
        margin-bottom: -5px;
    }
}

.connected_icon {
    width: 20px;
    height: 20px;

    @include mobile {
        width: 14px;
        height: 14px;
    }
}

.connected_text {
    @include font(400, 16px, 24px);
    color: #56D741;
    
    @include mobile {
      @include font(400, 14px, 21px);
  }
}
