@import "src/styles/base";

$bg_gradient: linear-gradient(236.5deg, rgba(137, 32, 254, 0.8) 17.25%, rgba(255, 11, 229, 0.8) 128.43%);

.container_border {
  display: flex;
  width: 278px;
  height: 50px;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0);
  border: 1px solid rgba(137, 32, 254, 1);
  transition: 0.4s;

  &:hover {
    border: 1px solid rgba(137, 32, 254, 0);
    .button {
      transition: 0.4s;
      opacity: 1;
    }
  }
}

.button {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background: $bg_gradient;
  opacity: 0;
  transition: 0.4s;
}

.text {
  @include font(500, 18px, 21px);
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
}
