@import "src/styles/base";

.container {
  width: 128px;
  height: 40px;
  border: none;
  outline: none;
  background: #0F0F36;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #8920FE;
  }
}

.tab_name_text {
  @include font(700, 16px, 24px);
  text-transform: uppercase;
  color: white;
}
