@import "src/styles/base";

.container {
  @include tablet {
    width: 166px;
    height: 29px;
  }
  @include mobile {
    width: 153px;
    height: 36px;
  }

  width: 256px;
  height: 44px;
  user-select: none;
  -webkit-user-select: none;
  border-radius: 8px;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    background: rgba(137, 32, 254, 0.4);
  }
}

.selected {
  background: #8920FE;
  &:hover {
    background: #8920FE;
  }
}

.inner_container {
  @include tablet {
    margin: 4px 0 4px 10px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 6px 0 6px 10px;
}

.tab_icon {
  @include mobile {
    width: 22px;
    height: 22px;
  }
  @include tablet {
    width: 21px;
    height: 21px;
  }
  width: 32px;
  height: 32px;
}

.tab_name {
  @include mobile {
    @include font(500, 16px, 24px);
  }
  @include tablet {
    @include font(500, 16px, 24px);
  }
  @include font(500, 18px, 21px);
}
