@import "src/styles/base";

.container {
  display: flex;
  flex-direction: row;
}

.user_avatar {
  @include tablet {
    width: 32px;
    height: 32px;
  }
  width: 50px;
  height: 50px;
  aspect-ratio: 1 / 1;
  margin-right: 10px;
  border-radius: 25px;
}

.text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.username_text {
  @include tablet {
    @include font(500, 16px, 18px);
  }
  @include font(500, 18px, 21px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.email_text {
  @include tablet {
    @include font(400, 14px, 16px);
  }
  @include font(400, 18px, 21px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
