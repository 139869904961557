@import "src/styles/base";

.container {
  @include horizontal_tablet {
    width: 184px;
  }
  @include vertical_tablet {
    width: 204px;
    border-right: none;
  }
  width: 285px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.inner_container {
  @include tablet {
    margin-right: 20px;
  }
  margin-right: 30px;
}

.service_logo_container {
  @include tablet {
    margin: 16px 0;
    gap: 17px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 25px 0;
  gap: 26px;
}

.service_logo {
  @include tablet {
    width: 130px;
    height: 39px;
  }
  width: 200px;
  height: 60px;
  object-fit: cover;
}

.logout_button {
  @include tablet {
    width: 26px;
    height: 26px;
  }
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #8920FE;
  border-radius: 8px;
  transition: 0.4s;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background: rgba(137, 32, 254);
    opacity: 0.4;
  }
}

.logout_icon {
  @include tablet {
    width: 18px;
    height: 18px;
  }
  width: 28px;
  height: 28px;
  margin: auto;
}
