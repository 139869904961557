@import "src/styles/base";

.mob_dropdown_container {
  width: 130px;
  height: 32px;
}

.mob_dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  color: white;
  padding: 1px 5px;
  outline: none;
  border: none;
}

.mob_email {
  @include font(400, 16px, 24px);
  margin-right: 5px;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.avatar_circle {
  display: flex;
  border-radius: 50px;
  background: linear-gradient(53.11deg, #45C4F9 17.79%, #8920FE 41.16%, #FF0BE5 69.44%, #FFC800 90.96%);
}

.mob_avatar_img {
  opacity: 0.8;
  padding: 1px;
}
