.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tabs_container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.search_field {
  display: flex;
  margin-left: auto;
}
