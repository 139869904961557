@import "src/styles/base";

$premium_border_gradient: linear-gradient(53.11deg, #45C4F9 17.79%, #8920FE 41.16%, #FF0BE5 69.44%, #FFC800);
$premium_bg_gradient: linear-gradient(53.11deg, rgba(69, 196, 249, 0.3) 17.79%, rgba(137, 32, 254, 0.3) 41.16%, rgba(255, 11, 229, 0.3) 69.44%, rgba(255, 200, 0, 0.3) 90.96%);

.container {
  @include horizontal_tablet {
    width: 148px;
    height: 30px;
  }
  @include vertical_tablet {
    width: 162px;
    height: 30px;
  }
  width: 285px;
  height: 39px;
  background: #0F0F36;
  border-radius: 20px;
  text-align: center;
  white-space: nowrap;
  margin-right: 30px;
}

.container_border {
  border: 1px solid transparent;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(#0F0F36, #0F0F36) padding-box, $premium_border_gradient border-box;
}

.button {
  @include horizontal_tablet {
    @include font(500, 14px, 21px);
  }
  @include vertical_tablet {
    @include font(500, 14px, 21px);
  }
  @include font(500, 16px, 24px);
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border-width: 0;
  color: white;
  z-index: 100;
  text-decoration: none;

  &:hover {
    border-radius: 20px;
    background-image: $premium_bg_gradient;

    &:before {
      opacity: 1;
      color: white;
    }
  }

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: $premium_bg_gradient;
    content: '';
    display: block;
    border-radius: 20px;
    opacity: 0;
    transition: opacity 1.5s;
    z-index: -100;
  }
}

.text {
  opacity: 1;
}
