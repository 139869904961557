@import "src/styles/base";
@import "../../../main-page/components/sidebar/styles/SideBarForm.module";

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.overlay {
  @include tablet {
    left: $tablet_sidebar_width;
  }
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  animation: fade-in 0.2s ease-in;
  display: flex;
  position: fixed;
  top: 0;
  left: $mobile_sidebar_width;
  right: 0;
  bottom: 0;
}

.modal {
  background: #161650;
  animation: fade-in 0.2s ease-in;
}

.modal_fade_out {
  animation: fade-out 0.2s ease-out;
}

.wrapper {
  margin-left: 20px;
}

.container {
  @include tablet {
    width: 450px - $tablet_sidebar_width;
  }
  width: 279px - $mobile_sidebar_width;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inner_container {
  margin: 0 15px;
}

.service_logo {
  margin: 15px auto;
  width: 153px;
  height: 46px;
}

.user_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  overflow: hidden;
}

.user_avatar {
  width: 22px;
  height: 22px;
}

.user_email {
  @include font(500, 16px, 24px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
