@import "src/styles/base";

.menu_container {
  width: 24px;
  height: 24px;
}

.library_info_header {
  display: flex;
  align-items: center;
}

.info_header {
  @include font(600, 16px, 19px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  img {
    width: 24px;
    height: 24px;
  }
}


.header_items {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}