@import "../../../ModalStyleBase.module";

.transfer_ended_wrapper {

  @include mobile {
    height: 100vh;
    width: 100vw;
    position: static;
  }

  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 70px;
  border-radius: 10px;
  background-image: $modal_background_gradient_color;
}

.close_btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;
}

.modal_icon {
  @include mobile {
    margin-top: 113px;
  }
}

.modal_text {
  margin-top: 30px;
  margin-bottom: 30px;
  @include font(500, 18px, 21px);
}

.items {
  width: 350px;
  height: 42vh;
}

.modal_button {
  @include mobile() {
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
  }
  text-transform: uppercase;
  margin-top: 40px;
  width: 278px;
  height: 50px;
}
