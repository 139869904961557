@import "src/styles/base";

.container {
  @include horizontal_tablet {
    width: 200px;
    height: 30px;
  }
  @include vertical_tablet {
    height: 30px;
  }
  display: flex;
  flex-direction: row;
  width: 285px;
  height: 40px;
  background: #282489;
  border-radius: 20px;
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.dropButton {
  @include horizontal_tablet {
    @include font(400, 14px, 21px);
    width: 200px;
    height: 30px;
  }
  @include vertical_tablet {
    @include font(400, 14px, 21px);
    height: 30px;
  }
  @include font(400, 16px, 24px);
  width: 285px;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 11px 8px;
  background: transparent;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  outline: none;
}

.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.avatar_image {
  @include horizontal_tablet {
    width: 21px;
    height: 21px;
  }
  @include vertical_tablet {
    width: 21px;
    height: 21px;
  }
  width: 28px;
  height: 28px;
  padding-right: 8px;
}

.arrow_image {
  @include horizontal_tablet {
    width: 18px;
    height: 18px;
  }
  @include vertical_tablet {
    width: 18px;
    height: 18px;
  }
  width: 24px;
  height: 24px;
  margin-left: auto;
}
