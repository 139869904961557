@import 'src/styles/base';

$show_all_bgr: linear-gradient(#0F0F36, #0F0F36) padding-box,
linear-gradient(104.26deg, rgba(132, 94, 255, 0.8) 18.1%, rgba(250, 62, 144, 0.8) 67.67%) border-box;

.container {
  @include mobile {
    height: 100px;
  }
  width: 100%;
  height: 334px;
  background: rgba(15, 15, 54, 0.4);
  border: 1px solid rgba(95, 95, 211, 0.5);
  border-radius: 20px;
  box-sizing: border-box;
  margin-top: 29px;
  margin-bottom: 20px;
}

.inner_container {
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  overflow-x: hidden;
}

.header_text {
  @include font(500, 18px, 21px);
  margin-top: 30px;
  margin-bottom: 29px;
}

.scroller {
  @include scroller(0, 6px, auto, hidden);
  flex: 1;
}

.services_container {
  display: flex;
  flex-direction: row;
  gap: 43px;
  padding-bottom: 15px;

  @include mobile {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  }
}

.show_all_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  width: 282px;
  height: 39px;
  align-self: center;
  background: #0F0F36;
  border-radius: 20px;
}

.show_all_container {
  @include font(400, 16px, 24px);
  width: 100%;
  // height: 100%;
  border: 1px solid transparent;
  border-radius: 20px;
  background: $show_all_bgr;
}

.show_all {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 20px;
  border-width: 0;
  color: white;
  z-index: 100;
  text-decoration: none;

  &:hover {
    border-radius: 20px;
    cursor: pointer;
    background-image: linear-gradient(104deg, rgba(132, 94, 255, 0.70) 18.1%, rgba(250, 62, 144, 0.70) 67.67%);

    &:before {
      opacity: 1;
      color: white;
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: linear-gradient(104deg, rgba(132, 94, 255, 0.90) 18.1%, rgba(250, 62, 144, 0.90) 67.67%);
    border-radius: 20px;
    opacity: 0;
    transition: opacity 1.5s;
    z-index: -100;
  }
}

