@import '/src/styles/base';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: 100%;
  background: transparent;
}

.text_wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.title {
  @include font(500, 40px, 150%);
  text-align: center;
  text-transform: uppercase;
}

.details {
  @include font(500, 18px, normal);
  text-align: center;
}

.button {
  display: flex;
  width: 278px;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
}

@mixin dots($count) {
  $text-shadow: ();
  @for $i from 0 through $count {
    $text-shadow: $text-shadow,
    (-.5+(random()) * 3) + em (-.5+(random()) * 3) + em 7px hsla(random() * 360, 100%, 50%, .5);
  }
  text-shadow: $text-shadow;
}

.ball {
  position: absolute;
  z-index: -999;
  display: block;
  font: 5vmin/1.3 Serif;
  overflow: hidden;
  font-size: 52px;
  color: transparent;
}

.ball::before, .ball::after,
.ball::before, .ball::after {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  content: '.';
  mix-blend-mode: screen;
  animation: 44s -27s move infinite ease-in-out alternate;
}


.ball::before {
  @include dots(20);
  animation-duration: 44s;
  animation-delay: -27s;
}

.ball::after {
  @include dots(20);
  animation-duration: 43s;
  animation-delay: -32s;
}

.ball::before {
  @include dots(20);
  animation-duration: 42s;
  animation-delay: -23s;
}

.ball::after {
  @include dots(20);
  animation-duration: 41s;
  animation-delay: -19s;
}


@keyframes move {
  from {
    transform: rotate(0deg) scale(12) translateX(-20px);
  }
  to {
    transform: rotate(360deg) scale(18) translateX(20px);
  }
}
