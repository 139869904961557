@import "../../../../ModalStyleBase.module.scss";

.status_body {
    margin: 30px 25px;

    @include mobile {
        margin: 20px;
    }
}

.transfer_status {
    @include font(500, 16px, 24px);

    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-bottom: 10px;

    @include mobile {
        @include font(500, 14px, 21px);
        height: 10px;
    }
}

.progress_bar {
    height: 20px;
    background: #212178;
    border-radius: 20px;

    @include mobile {
        height: 10px;
    }

    .progress_color {
        transition: 0.5s;
        background: linear-gradient(240.36deg, #45C4F9 -4.25%, #8920FE 43.01%, #FF0BE5 100.2%);
        height: 100%;
        border-radius: 20px;
    }
}