@import "src/styles/base";

.container {
  position: relative;
  width: 600px;
  background: #1C1C64;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include mobile {
    height: 80vh;
    width: 90vw;
    padding: 0;
  }
}

.close_btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 20px;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.25);
  }
}

.info {
  @include font(400, 20px, 140%);
  margin-top: 20px;
}

.next_button {
  margin: 24px 0;
}

.email {
  color: $link_color;
}
