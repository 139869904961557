@import "../../../ModalStyleBase.module.scss";

.modal_wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 646px;
    background: $modal_background_gradient_color;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 4px 80px rgba(12, 12, 12, 0.3);
    text-align: center;

    @include mobile {
        height: 100vh;
    }

    @include desktop {
        width: 1100px;
    }
}

.main_icon {
    width: 230px;
    height: 230px;

    @include mobile {
        width: 160px;
        height: 160px;
    }
}

.close_btn {
    height: 24px;
    width: 24px;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
    position: absolute;
    right: 0;

    @include mobile {
        width: 22px;
        height: 22px;
    }
}

.thank_you_text {
    @include font(600, 48px, 57px);
    margin-bottom: 20px;

    @include mobile {
        @include font(500, 24px, 120%);
    }
}

.subscribe_text {
    @include font(400, 20px, 24px);
    margin-bottom: 25px;

    @include mobile {
        @include font(400, 16px, 150%);
        margin-bottom: 20px;
    }
}

.subscribe_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;

    @include mobile {
        display: block;
        padding: 0 20px;
    }
}

.input_container {
    width: 443px;

    @include mobile {
        width: 100%;
    }
}

.btn_container {
    margin-top: 5px;
    width: 127px;

    @include mobile {
        margin-top: 20px;
        width: 100%;
    }
}

.rate_text {
    @include font(400, 20px, 24px);
    margin-top: 35px;
    margin-bottom: 20px;

    @include mobile {
        position: relative;
        margin-top: 15vh;
        @include font(400, 16px, 24px);
    }
}