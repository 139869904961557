@import "src/styles/base";

.container {
  @include tablet {
    width: 165px;
    margin-top: 16px;
    margin-bottom: 14px;
  }
  width: 255px;
  margin-top: 25px;
  margin-bottom: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
