@import "src/styles/base";

.menu_container {
  @include vertical_tablet {
    margin: 0 25px;
  }
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons_container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.transfer_header_container {
  margin-right: 29px;
}
