@import "src/styles/base";

$background_gradient: linear-gradient(201.32deg, rgba(69, 196, 249, 0.4) -24.51%, rgba(137, 32, 254, 0.4) 66.66%, rgba(255, 11, 229, 0.4) 141.36%);
$sidebar_width: 158px;
$mobile_sidebar_width: 96px;
$tablet_sidebar_width: 131px;

.sidebar_form {
  @include mobile {
    width: $mobile_sidebar_width;
    height: 100%;
  }
  @include horizontal_tablet {
    width: $tablet_sidebar_width;
    height: 100%;
  }
  @include vertical_tablet {
    width: $tablet_sidebar_width;
    height: 100%;
  }
  display: flex;
  flex-direction: column;
  background: $background_gradient;
  width: $sidebar_width;
  height: 100vh;
  position: fixed;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
}

.scroller {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  @include scroller(6px, 0, hidden, auto, $track_color: transparent);
}

